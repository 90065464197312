import { atom } from "recoil";

export const isMobileDevice = atom({
    key: "isMobileDevice",
    default: window.innerWidth < 480,
});

export const onTopOfPage = atom({
    key: "onTopOfPage",
    default: true,
});