import React from 'react'

export default function MenuButton(props) {
    return (
        <div style={{ padding: '15px',cursor:'pointer'}}>
            <h1 style={{ color: props.topScroll ? 'white' : 'black', marginBottom: 0 }}>
                <b>{props.title}</b>
            </h1>
        </div>
    )
}
