import React, { useEffect } from 'react'
import { Layout, Button, Row, Col } from 'antd';
import { useRecoilState } from 'recoil';
import { isMobileDevice, onTopOfPage } from '../store/atom';
import { AppstoreAddOutlined, BgColorsOutlined, CheckCircleTwoTone, ClockCircleOutlined, CloseCircleTwoTone, CommentOutlined, EditOutlined, EnvironmentOutlined, FileImageOutlined, FormOutlined, GiftOutlined, GlobalOutlined, HeartOutlined, InstagramOutlined, RightOutlined, SendOutlined, SoundOutlined, WhatsAppOutlined, YoutubeOutlined } from '@ant-design/icons';
import HeaderMenu from '../components/HeaderMenu';
import WebFont from 'webfontloader';
import { Element } from 'react-scroll'

const { Content, Footer } = Layout;
export default function Home() {
    const [isMobile, setMobile] = useRecoilState(isMobileDevice);
    const [onTopPage, setOnTopPage] = useRecoilState(onTopOfPage);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Dancing Script', 'Fugaz One']
            }
        });
        const handleResize = () => { setMobile(window.innerWidth < 768) };
        window.onscroll = function () {
            if (window.pageYOffset === 0) {
                setOnTopPage(true);
            } else {
                setOnTopPage(false);
            }
        };
        // subscribe event
        window.addEventListener("resize", handleResize);
        return () => {
            // unsubscribe event
            window.addEventListener("resize", handleResize);
        };
    }, [setMobile, setOnTopPage]);
    return (
        <Layout>
            <HeaderMenu topScroll={onTopPage} mobile={isMobile} />
            <Content >
                <Element name='home' className="first-section-background" >
                    <div style={{ width: '100%', height: '100px', backgroundColor: 'transparent' }} />
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
                        <Row justify='center'>
                            <Col xs={24} sm={24} md={12} lg={10} xl={8} textAlign="right">
                                <Row justify={isMobile ? 'center' : 'end'}>
                                    <div style={{ padding: '20px', textAlign: (isMobile ? 'center' : '') }}>
                                        <img alt='' src={window.location.origin + '/svg/WebDevices.svg'} width='100%' />
                                    </div>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={10} xl={8} textAlign="right">
                                <Row justify={isMobile ? 'center' : 'start'}>
                                    <div style={{ padding: '20px', textAlign: (isMobile ? 'center' : '') }}>
                                        <h1 style={{ color: 'white', fontFamily: 'Dancing Script' }}>Alinvite Web Invitation</h1><br />
                                        <h1 style={{ color: 'white', fontSize: 25, fontFamily: 'Fugaz One' }}><b>Undangan Berbasis Website</b></h1>
                                        <h1 style={{ color: 'white', fontSize: 20, fontFamily: 'Fugaz One' }}><b>Share Your Moment Anywhere Anytime</b></h1>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <Row justify='center'>
                            <Col>
                                <Button type='default' shape='round'><b>Info Lebih Lanjut</b> <RightOutlined /></Button>
                            </Col>
                        </Row>
                    </div>
                </Element>
                <Element name='featur' style={{ width: '100%', textAlign: 'center', padding: '80px 20px' }}>
                    <Row justify='center' gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                            <h1>Featur</h1>
                            <h1 style={{ color: 'black', fontSize: 20, fontFamily: 'Fugaz One' }}><b>Terdapat Banyak Fitur Menarik <br />Untuk Memanjakan Undangan Digital Anda</b></h1>
                        </Col>
                    </Row>
                    <Row justify='center' gutter={16}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} >
                            <div className='featur-box'>
                                <GlobalOutlined style={{ fontSize: '50px' }} />
                                <h1>Domain</h1>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} >
                            <div className='featur-box'>
                                <ClockCircleOutlined style={{ fontSize: '50px' }} />
                                <h1>Count Down</h1>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} >
                            <div className='featur-box'>
                                <img alt='' src={window.location.origin + '/svg/HandsPraying.svg'} height='50px' />
                                {/* <GlobalOutlined style={{ fontSize: '50px' }} /> */}
                                <h1>Ucapan Doa</h1>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} >
                            <div className='featur-box'>
                                <FileImageOutlined style={{ fontSize: '50px' }} />
                                <h1>Foto Gallery</h1>
                            </div>
                        </Col>
                    </Row>
                    <Row justify='center' gutter={16}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} >
                            <div className='featur-box'>
                                <EnvironmentOutlined style={{ fontSize: '50px' }} />
                                <h1>Peta Lokasi</h1>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} >
                            <div className='featur-box'>
                                <CommentOutlined style={{ fontSize: '50px' }} />
                                <h1>Social Media</h1>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} >
                            <div className='featur-box'>
                                <HeartOutlined style={{ fontSize: '50px' }} />
                                <h1>Love Story</h1>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} >
                            <div className='featur-box'>
                                <SoundOutlined style={{ fontSize: '50px' }} />
                                <h1>Backsound Music</h1>
                            </div>
                        </Col>
                    </Row>
                    <Row justify='center' gutter={16}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} >
                            <div className='featur-box'>
                                <YoutubeOutlined style={{ fontSize: '50px' }} />
                                <h1>Video Galeri</h1>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} >
                            <div className='featur-box'>
                                <FormOutlined style={{ fontSize: '50px' }} />
                                <h1>RSVP</h1>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} >
                            <div className='featur-box'>
                                <GiftOutlined style={{ fontSize: '50px' }} />
                                <h1>Hadiah Online</h1>
                            </div>
                        </Col>
                    </Row>
                </Element>
                <Element name='pemesanan' style={{ width: '100%', textAlign: 'center', padding: '80px 20px', backgroundColor: '#0085ff40' }}>
                    <Row justify='center' gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                            <h1>Cara Pemesanan</h1>
                            <h1 style={{ color: 'black', fontSize: 20, fontFamily: 'Fugaz One' }}><b>Ikuti Langkah Berikut Untuk Memulai</b></h1>
                        </Col>
                    </Row>
                    <Row justify='center' gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={16} >
                            <div className="timeline">
                                <div className="container left">
                                    <GlobalOutlined className='icon' />
                                    <div className="content">
                                        <h2><b>Nama Domain</b></h2>
                                        <p>
                                            Anda dapat menentukan nama domain yang anda inginkan
                                        </p>
                                    </div>
                                </div>
                                <div className="container right">
                                    <BgColorsOutlined className='icon' />
                                    <div className="content">
                                        <h2><b>Pilih Tema</b></h2>
                                        <p>
                                            Berbagain macam tema banyak tersedia. Masih Kurang? Hubungi WA kami.
                                        </p>
                                    </div>
                                </div>
                                <div className="container left">
                                    <EditOutlined className='icon' />
                                    <div className="content">
                                        <h2><b>Isi Data</b></h2>
                                        <p>
                                            Sampaikan informasi acara yang ingin anda tampilkan pada undangan.
                                        </p>
                                    </div>
                                </div>
                                <div className="container right">
                                    <AppstoreAddOutlined className='icon' />
                                    <div className="content">
                                        <h2><b>Pengerjaan</b></h2>
                                        <p>
                                            Kami akan melakukan yang terbaik untuk mewujudkan keinginan anda
                                        </p>
                                    </div>
                                </div>
                                <div className="container left">
                                    <SendOutlined className='icon' />
                                    <div className="content">
                                        <h2><b>Bagikan Undangan</b></h2>
                                        <p>
                                            Undangan anda sudah siap dibagikan dimanapun kapanpun.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Element>
                <Element name='price' style={{ width: '100%', textAlign: 'center', padding: '80px 20px' }}>
                    <Row justify='center' gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                            <h1>Price</h1>
                            <h1 style={{ color: 'black', fontSize: 20, fontFamily: 'Fugaz One' }}><b>Pilih Paket Yang Anda Inginkan</b></h1>
                        </Col>
                    </Row>
                    <Row justify='center' gutter={16}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={6} >
                            <div className='price-box'>
                                <h1><b>Silver</b></h1>
                                <h1 style={{ fontSize: 40 }}>Rp <b>149.000</b></h1><br />
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Custom alinvite.com/"path domain"</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Tema Sesuai Template</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Hitung Mundur</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Fitur Kepada</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Ucapan Doa</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Foto Gallery (6)</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Alamat Lokasi</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Peta Lokasi</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Link Sosial Media</h1>

                                <h1 style={{ textDecoration: 'line-through' }}><CloseCircleTwoTone twoToneColor="#eb2f96" /> Love Story </h1>
                                <h1 style={{ textDecoration: 'line-through' }}><CloseCircleTwoTone twoToneColor="#eb2f96" /> Musik</h1>
                                <h1 style={{ textDecoration: 'line-through' }}><CloseCircleTwoTone twoToneColor="#eb2f96" /> Video Galeri</h1>
                                <h1 style={{ textDecoration: 'line-through' }}><CloseCircleTwoTone twoToneColor="#eb2f96" /> RSVP</h1>
                                <h1 style={{ textDecoration: 'line-through' }}><CloseCircleTwoTone twoToneColor="#eb2f96" /> Hadiah Online</h1>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={6} >
                            <div className='price-box'>
                                <h1><b>Gold</b></h1>
                                <h1 style={{ fontSize: 40 }}>Rp <b>299.000</b></h1><br />
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Custom "sub domain".alinvite.com</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Tema Sesuai Template</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Hitung Mundur</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Fitur Kepada</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Kolom Ucapan</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Foto Gallery </h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Alamat Lokasi</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Peta Lokasi</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Link Sosial Media</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Love Story </h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Musik</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Video Galeri</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> RSVP</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Hadiah Online</h1>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={6} >
                            <div className='price-box'>
                                <h1><b>Platinum</b></h1>
                                <h1 style={{ fontSize: 40 }}>Rp <b>599.000</b></h1><br />
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Custom "domain".com</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> Desain Tema Bebas</h1>
                                <h1><CheckCircleTwoTone twoToneColor="#52c41a" /> <b>All Featur</b></h1>
                            </div>
                        </Col>
                    </Row>
                </Element>
                <Element name='contact' style={{ width: '100%', textAlign: 'center', padding: '80px 20px' }}>
                    <Row justify='center' gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                            <h1>Contact</h1>
                            <h1 style={{ color: 'black', fontSize: 20, fontFamily: 'Fugaz One' }}><b>Pesan Sekarang Juga</b></h1>
                        </Col>
                    </Row>
                    <Row justify='center' gutter={16}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={6} >
                            <img alt='' src={window.location.origin + '/svg/ContactUs.svg'} width='100%' />
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={6} >
                            <Row justify='center' gutter={16}>
                                <Col>
                                    <a href='https://wa.me/6282216725315' target='_blank' rel='noreferrer'>
                                        <Button type='dashed' shape='round' size='large'>
                                            <WhatsAppOutlined /> Hubungi Kami
                                        </Button>
                                    </a>
                                </Col>
                                <Col>
                                    <a href='https://www.instagram.com/al.invite/' target='_blank' rel='noreferrer'>
                                        <Button type='dashed' shape='round' size='large'>
                                            <InstagramOutlined /> al.invite
                                        </Button>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Element>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                Alinvite Design By Lendra Zulfikar
            </Footer>
        </Layout>
    )
}
