import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from '../pages/Home'
import NotFound404 from '../pages/NotFound404'

export default function Router() {
    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' >
                        <Home/>
                    </Route>
                    <Route path='/:client' >
                        <div >Client </div>
                    </Route>
                    <Route path='*' component={NotFound404} />
                </Switch>
            </BrowserRouter>
        </div>
    )
}
