import React, { useEffect, useState } from 'react'
import { Button, Drawer, Layout } from 'antd'
import { MenuOutlined, WhatsAppOutlined } from '@ant-design/icons'
import MenuButton from './MenuButton'
import WebFont from 'webfontloader'
import { Link } from 'react-scroll'

const { Header } = Layout;

export default function HeaderMenu(props) {
    const [drawer, setDrawer] = useState(false)
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Dancing Script']
            }
        });
    }, []);
    return (
        <Header className="site-layout-background header-style" style={{ padding: "0 10px", width: `100%`, height: '100px', position: 'fixed', zIndex: 3, backgroundColor: props.topScroll ? 'transparent' : 'white' }}>
            <div className='float-left-header'>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', display: 'flex' }}>
                    <div style={{ paddingRight: '10px' }}>
                        <img alt='' src={window.location.origin + '/alinvite.png'} width={70} />
                    </div>
                    {!props.mobile && <h1 style={{ color: props.topScroll ? 'white' : 'black', marginBottom: 0, fontFamily: 'Dancing Script' }}><b>Alinvite</b></h1>}
                </div>
            </div>
            <div className='float-right-header'>
                <div style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                    {
                        props.mobile ?
                            <div>
                                <Button onClick={() => setDrawer(true)} type={props.topScroll ? 'primary' : 'default'} size='large'><MenuOutlined /></Button>
                                <Drawer
                                    placement="left"
                                    closable={true}
                                    onClose={() => setDrawer(false)}
                                    visible={drawer}
                                    key='drawerMenu'
                                >
                                    <Link activeClass="menu-drawer-active" to="home" spy={true} smooth={true} offset={-100} duration={250} >
                                        <h3>Home</h3>
                                    </Link>
                                    <Link activeClass="menu-drawer-active" to="featur" spy={true} smooth={true} offset={-100} duration={250} >
                                        <h3>Featur</h3>
                                    </Link>
                                    <Link activeClass="menu-drawer-active" to="pemesanan" spy={true} smooth={true} offset={-100} duration={250} >
                                        <h3>Pemesanan</h3>
                                    </Link>
                                    <Link activeClass="menu-drawer-active" to="price" spy={true} smooth={true} offset={-100} duration={250} >
                                        <h3>Price</h3>
                                    </Link>
                                </Drawer>
                            </div>
                            :
                            <div style={{ paddingRight: '10px', paddingLeft: '10px', display: 'flex' }}>
                                <Link activeClass="menu-active" to="home" spy={true} smooth={true} offset={-100} duration={250}>
                                    <MenuButton topScroll={props.topScroll} title='Home' />
                                </Link>
                                <Link activeClass="menu-active" to="featur" spy={true} smooth={true} offset={-100} duration={250}>
                                    <MenuButton topScroll={props.topScroll} title='Featur' />
                                </Link>
                                <Link activeClass="menu-active" to="pemesanan" spy={true} smooth={true} offset={-100} duration={250}>
                                    <MenuButton topScroll={props.topScroll} title='Pemesanan' />
                                </Link>
                                <Link activeClass="menu-active" to="price" spy={true} smooth={true} offset={-100} duration={250}>
                                    <MenuButton topScroll={props.topScroll} title='Price' />
                                </Link>
                            </div>
                    }
                </div>

                <Link activeClass="menu-active" to="contact" smooth={true} duration={250}>
                    <Button type={props.topScroll ? 'default' : 'primary'} shape='round' size='large'>
                        {props.mobile?<WhatsAppOutlined />:<b>Pesan Sekarang</b>}
                    </Button>
                </Link>
            </div>

        </Header>
    )
}
